body.journal .menu-journal {
    opacity: 0;
}
body.journal .menu-journal:hover{
    opacity: 1 !important;
}

.journal-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}


.journal-title {
    height: 50px;
    max-width: 768px;
    width: calc(100% - 280px);
    /*width: 100%;*/
    margin: 0 auto;
    background:white;
    position:absolute;
    top:0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 100;
    text-align: center;
    padding-top: 24px;
}

.journal-content {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.journal-content > div{
    padding:50px 0;
    max-width: 768px;
    z-index: 10;
    margin: 0 auto;
}


.journal-content  .journal-swiper {
    width: 100%;
    height: 0;
    padding-bottom:68%;
    position: relative;
}
.journal-content  .journal-swiper .swiper-container{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
}
.journal-content  .journal-swiper .swiper-container .swiper-slide{
    background-color: aliceblue;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}


.journal-swiper .swiper-arrow{
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 20%;
    transform: translate(0, -50%);
    opacity: 0.25;
    z-index:100000;
    opacity: 0;
    height: calc(100% - 150px);
    transition: opacity 1s;
}
.journal-swiper .swiper-arrow:hover{
    opacity: 1 !important;
}
.journal-swiper .swiper-arrow svg{
    width: 24px;
    height: 24px;
}
.journal-swiper .swiper-arrow.next{
    cursor: url(http://huangrui.oss-cn-beijing.aliyuncs.com/RightArrow.png) 1 25, auto;
    right: 10px;
}
.journal-swiper .swiper-arrow.prev{
    left: 10px;
    cursor: url(http://huangrui.oss-cn-beijing.aliyuncs.com/LeftArrow.png) 1 25, auto;
}

.journal-swiper.mouse-moving .swiper-arrow{
    opacity: 0.25;
}



.journal-content .journal-md {
    padding-top: 24px;
}
.journal-content .journal-md p{
    -webkit-user-select: text;  /* Chrome 49+ */
    -moz-user-select: text;     /* Firefox 43+ */
    -ms-user-select: text;      /* No support yet */
    user-select: text;          /* Likely future */
}
.journal-content .journal-md p::-moz-selection{
    /*color: rgba(0,0,0,0);*/
    color: rgba(255,255,255,1);
    background: rgba(0,0,0,1);
    /*background: rgba(255,255,255,1);*/
}
.journal-content .journal-md p::selection {
    color: rgba(255,255,255,1);
    /*color: rgba(0,0,0,0);*/
    background: rgba(0,0,0,1);
    /*background: rgba(255,255,255,1);*/
}

.journal-content .journal-md p > img{
    max-width: 60% !important;
    height: auto !important;
    margin: 0 auto;
    display: none !important;
}

.journal-content > div.journal-footer {
    padding: 10px 0 50px 0;
    width: 100%;
    max-width: unset;
    border-top: solid black 3px;
}

.journal-footer .journal-footer-swiper {
    max-width: 768px;
    z-index: 10;
    position: relative;
    margin: 0 auto;
}
.journal-footer .journal-footer-swiper > div {
    padding-bottom:20%;
    height: 0;
    width: calc(100% + 24px);
    margin: 0 -12px;
    position: relative;
}
.journal-footer .journal-footer-swiper .swiper-container {
    position: absolute;
    width:100%;
    top:0;
    left:0;
    height: 100%;
}
.journal-footer .journal-footer-swiper .swiper-slide{
    padding:0 12px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.journal-footer .journal-footer-swiper .swiper-slide>div{
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
.journal-footer .journal-footer-swiper .swiper-slide:hover>div{
    background-image: unset !important;
    background-color: white;
}

.journal-footer .journal-footer-swiper .swiper-slide>div>div{
    width: 100%;
    height: 100%;
    position: relative;
}
.journal-footer .journal-footer-swiper .swiper-slide>div>div>a{
    display: none;
    text-align: center;
    width: 80%;
    position: absolute;;
    top: 50%;
    left: 50%;
    overflow: hidden;
    transform: translate(-50%, -50%);
}

.journal-footer .journal-footer-date {
    font-size: 11px;
    line-height: 16px;
}
.journal-footer .journal-footer-title {
    font-size: 16px;
    line-height: 21px;
}

.journal-footer .journal-footer-swiper .swiper-slide:hover>div>div>a{
    display:block;
}
body.journal .journal-date {
    display: none;
}

@media only screen and (max-width: 768px) {
    body.journal .app-menu-closed  .content {
        height: calc(100% - 104px);
    }
    body.journal .app > div {
        padding: 0;
    }
    body.journal .content {
        width: 100%;
        margin: 0;
        box-sizing: border-box;
    }
    body.journal .journal-container {
        box-sizing: border-box;
        padding: 0 24px;
        -webkit-overflow-scrolling: touch;
        overflow: auto;
        font-size: 16px;
        line-height: 20px;
    }
    body.journal .journal-content {
        height: unset;
    }
    body.journal .mobile-menu{
        padding: 0 24px;
        box-sizing: border-box;
    }
    body.journal .journal-title {
        font-size: 20px;
        line-height: 32px;
        width: 100%;
        padding: 0;
    }
    body.journal .journal-date {
        display: block;
        padding-top: 24px;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
    }
    body.journal .journal-footer {
        display: none;
    }
    .journal-content > div {
        padding-top: 30px;
    }
}
