@import '~swiper/dist/css/swiper.css';

.app {
    width: 100%;
    height: 100%;
}

.app > div{
    width: 100%;
    height: 100%;
    padding: 0 24px;
    position: relative;
    box-sizing: border-box;
}

.content {
    z-index: 10;
    margin: 0 -24px;
    width: calc(100% + 48px);
    height: 100%;
    position: relative;
}

.menu {
    position: absolute;
    z-index: 11;
}

.menu-home {
    left:24px;
    top:24px
}

.menu-works {
    right:24px;
    top:24px
}

.menu-journal {
    left:24px;
    bottom:24px
}

.menu-information {
    right:24px;
    bottom:24px
}

a.menu:hover{
    opacity: 0;
}

/*body.information .menu-information.corner-menu {*/
    /*display: none;*/
/*}*/

body.show-modal #root {
   display: none;
}

.loading-container, .loading-error-container {
    display:inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 30%;
    max-height: 30%;
    justify-content: center;
    align-items: center;
    transition: opacity 1s;
}

.loading-error-container {
    color: red;
}

.mobile-menu {
    display: none;
}

@media only screen and (max-width: 768px) {
    body {
        font-size: 16px;
        line-height: 20px;
    }
    a.menu:hover {
        opacity: 1;
    }
    .menu {
        display: none;
    }
    .menu-home {
        display: block;
        font-size: 22px;
        line-height: 32px;
        z-index: 12;
    }
    .mobile-menu {
        display: block;
        position: relative;
        width: 100%;
        z-index: 11;
    }
    .mobile-menu > a {
        display: block;
        line-height: 32px;
        font-size: 22px;
    }
    .menu-icon {
        text-align: right;
        padding-top: 24px;
        height: 32px;
    }
    .menu-icon-open, .menu-icon-close {
        display: none;
    }
    .menu-closed .menu-icon-open {
        display: inline-block;
    }
    .menu-open .menu-icon-close {
        display: inline-block;
    }
    .menu-closed > a{
        display: none;
    }
    body.home .app-menu-closed .mobile-menu {
        /*background: white;*/
        position: absolute;
        width: calc(100% - 48px);
    }
    body.works .menu-closed > a.works-nav{
        display: block;
    }
    body.information .menu-closed > a.information-nav{
        display: block;
    }
    body.journal .menu-closed > a.journal-nav{
        display: block;
    }
    .app-menu-open .mobile-menu {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .app-menu-open .content {
        height: calc(100% - 152px);
    }
    .loading-container, .loading-error-container {
        font-size: 16px;
    }
    .app > div {
        overflow: auto;
        height: auto;
        /*overflow: hidden;*/
    }
    .app-menu-open.app > div {
        height: 100%;
        overflow: hidden;
    }
}