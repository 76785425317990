
body.works .menu-works {
    opacity: 0;
}
body.works .menu-works:hover {
    opacity: 1;
}

.works-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    position: relative;
}

.works-module-nav {
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    padding: 24px 20px 5px;
    box-sizing: border-box;
    line-height: 21px;
    background: white;
    transform: translate(-50%, 0);
    width: calc(100% - 300px);
    margin: 0 auto;
    z-index: 100;
}

.active-work-tile {
    min-height: 69px;
    width: calc(100% - 300px);
    z-index: 100;
    margin: 0 auto;
    background: white;
    text-align: center;
    position: absolute;
    padding: 24px 20px 24px;
    bottom: 0;
    left: 50%;
    line-height: 21px;
    transform: translate(-50%, 0);
    box-sizing: border-box;
}

.works-module-nav .active {
    opacity: 0.25;
}
.works-module-nav a {
    padding: 0 0px;
}

.works-grids {
    padding: 90px 150px 60px;
    z-index: 10;
    width: 100%;
    box-sizing: border-box;;
    height: 100%;
    overflow: auto;
}

.works-grid {
    cursor: pointer;
    /*padding:12px 12px 12px 12px;*/
    padding: 20px 60px;
    width: 33.33%;
    display: inline-block;
    box-sizing: border-box;
}

.works-grid .cover {
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    transition: opacity 500ms;
}

.works-grid:hover .cover {
    opacity: 0;
    transition: opacity 500ms;
}

.works-gallery-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: white;
    z-index: 10000;
    overflow: hidden;
}

.works-gallery-content {
    zIndex: 10000;
    background: white;
}

.works-modal-wrapper .swiper-container {
    width: 100%;
    height: 100%;
}

.works-modal-wrapper .swiper-container .swiper-slide > .image {
    width: calc(100% - 400px);
    height: calc(100% - 300px);
    max-width: 1140px;
    max-height: 760px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -30px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.works-modal-wrapper .swiper-container .swiper-slide > .title {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    display: block;
    bottom: 24px;
}

.works-modal-wrapper{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.works-modal-wrapper .swiper-arrow{
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 20%;
    transform: translate(0, -50%);
    opacity: 0.25;
    z-index:100000;
    opacity: 0;
    height: calc(100% - 150px);
    transition: opacity 1s;
}
.works-modal-wrapper .swiper-arrow:hover{
    opacity: 1 !important;
}
.works-modal-wrapper .swiper-arrow svg{
    width: 24px;
    height: 24px;
}
.works-modal-wrapper .swiper-arrow.next{
    cursor: url(http://huangrui.oss-cn-beijing.aliyuncs.com/RightArrow.png) 1 25, auto;
    right: 10px;
}
.works-modal-wrapper .swiper-arrow.prev{
    left: 10px;
    cursor: url(http://huangrui.oss-cn-beijing.aliyuncs.com/LeftArrow.png) 1 25, auto;
}

.works-modal-wrapper.mouse-moving .swiper-arrow{
    opacity: 0.25;
}
.works-modal-wrapper.mouse-moving .works-modal-close{
    opacity: 1;
}

.works-modal-close {
    transition: opacity 1s;
    opacity: 0;
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
    z-index: 10000;
    display: inline-block;
}

.works-modal-close svg {
    width: 20px;
    height: 20px;
}

.works-modal-close:hover {
    opacity: 0 !important;
}


.works-modal-wrapper-open {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 40px;
    bottom: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: white;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    transform: translate(-50%, -50%);
    z-index: 10000;
    width: 100%;
    height: 100%;
}


.works-module-mobile-nav {
    display: none;
}



@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .works-grid {
        width: 33.33%;
    }
}

@media only screen and (max-width: 768px) {
    .active-work-tile {
        display: none;
    }
    .works-modal-wrapper .swiper-arrow {
        display: none !important;
    }
    .works-grid {
        width: 50%;
        padding: 48px 12px 12px 12px;
    }
    .works-grids {
        padding: 0;
        height: unset;
    }
    .works-module-nav {
        display: none;
    }
    .works-module-mobile-nav {
        display: block;
        box-sizing: border-box;
        padding: 24px 24px 0 24px;
    }
    .works-module-mobile-nav>a {
        display: block;
    }
    .works-module-mobile-nav>a.active {
        opacity: 0.25;
    }
    .works-modal-wrapper .swiper-container .swiper-slide > .image {
        width: calc(100% - 48px);
        height: calc(100% - 120px);
        top: 80px;
        left: 24px;
        transform: unset;
    }
    .works-modal-wrapper .swiper-container .swiper-slide > .title {
        width: 100%;
        left: 0;
        font-size: 16px;
        padding: 0 24px;
        line-height: 20px;
        text-align: center;
        transform: unset;
        box-sizing: border-box;
        top: calc(100% - 50px);
        max-height: 40px;
        overflow: hidden;
        bottom: unset;
    }
    .works-modal-close {
        opacity: 1;
        top: 5px;
    }
    body.works .app-menu-closed  .content {
        height: calc(100% - 104px);
    }
}
