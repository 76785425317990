@font-face {
    font-family: 'Graphik Web';
    src: url('http://huangrui-media.jilinchen.com/Graphik-MediumItalic-Web.woff2') format('woff2'),
    url('http://huangrui-media.jilinchen.com/Graphik-MediumItalic-Web.woff') format('woff');
    font-weight:  500;
    font-style:   italic;
    font-stretch: normal;
}

/*.Graphik-MediumItalic-Web {*/
    /*font-family: 'Graphik Web';*/
    /*font-weight:  500;*/
    /*font-style:   italic;*/
    /*font-stretch: normal;*/
/*}*/

@font-face {
    font-family: 'Graphik Web';
    src: url('http://huangrui-media.jilinchen.com/Graphik-Medium-Web.woff2') format('woff2'),
    url('http://huangrui-media.jilinchen.com/Graphik-Medium-Web.woff') format('woff');
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
}



html, body, #root {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}

body {
    /*min-height: 560px;*/
    font-size: 16px;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-family: 'Graphik Web';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}
a:visited, a {
    color: black;
}


* {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}



.app {
    width: 100%;
    height: 100%;
}

.app > div{
    width: 100%;
    height: 100%;
    padding: 0 24px;
    position: relative;
    box-sizing: border-box;
}

.content {
    z-index: 10;
    margin: 0 -24px;
    width: calc(100% + 48px);
    height: 100%;
    position: relative;
}

.menu {
    position: absolute;
    z-index: 11;
}

.menu-home {
    left:24px;
    top:24px
}

.menu-works {
    right:24px;
    top:24px
}

.menu-journal {
    left:24px;
    bottom:24px
}

.menu-information {
    right:24px;
    bottom:24px
}

a.menu:hover{
    opacity: 0;
}

/*body.information .menu-information.corner-menu {*/
    /*display: none;*/
/*}*/

body.show-modal #root {
   display: none;
}

.loading-container, .loading-error-container {
    display:inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 30%;
    max-height: 30%;
    justify-content: center;
    align-items: center;
    transition: opacity 1s;
}

.loading-error-container {
    color: red;
}

.mobile-menu {
    display: none;
}

@media only screen and (max-width: 768px) {
    body {
        font-size: 16px;
        line-height: 20px;
    }
    a.menu:hover {
        opacity: 1;
    }
    .menu {
        display: none;
    }
    .menu-home {
        display: block;
        font-size: 22px;
        line-height: 32px;
        z-index: 12;
    }
    .mobile-menu {
        display: block;
        position: relative;
        width: 100%;
        z-index: 11;
    }
    .mobile-menu > a {
        display: block;
        line-height: 32px;
        font-size: 22px;
    }
    .menu-icon {
        text-align: right;
        padding-top: 24px;
        height: 32px;
    }
    .menu-icon-open, .menu-icon-close {
        display: none;
    }
    .menu-closed .menu-icon-open {
        display: inline-block;
    }
    .menu-open .menu-icon-close {
        display: inline-block;
    }
    .menu-closed > a{
        display: none;
    }
    body.home .app-menu-closed .mobile-menu {
        /*background: white;*/
        position: absolute;
        width: calc(100% - 48px);
    }
    body.works .menu-closed > a.works-nav{
        display: block;
    }
    body.information .menu-closed > a.information-nav{
        display: block;
    }
    body.journal .menu-closed > a.journal-nav{
        display: block;
    }
    .app-menu-open .mobile-menu {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .app-menu-open .content {
        height: calc(100% - 152px);
    }
    .loading-container, .loading-error-container {
        font-size: 16px;
    }
    .app > div {
        overflow: auto;
        height: auto;
        /*overflow: hidden;*/
    }
    .app-menu-open.app > div {
        height: 100%;
        overflow: hidden;
    }
}
.home-entry, .home-loading, .home-error {
    display:flex;
    flex: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 1s;
}

.home-content {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

.home-content .swiper-container {
    z-index: 10;
    width: calc(100% + 50px);
    height: 100%;
    position: absolute;
    left: -29px;
}

.home-content .swiper-container .swiper-slide{
    border-right: solid black 1.5px;
    border-left: solid black 1.5px;
    box-sizing: border-box;
}

.home-entry, .home-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: block;
    text-align: center;
}

.home-content .swiper-container .swiper-slide > .slide-img{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: block;
    width: 60%;
    height: 60%;
    max-width: 1140px;
    max-height: 760px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
.home-content .swiper-container .swiper-slide > a > img{
    width: auto;
    height: auto;
    max-width: 75%;
    max-height: 75%;
}

.home-content .slide-title {
    z-index: 11;
    position: absolute;
    bottom: 24px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    display: inline-block;
}

body.show-entry .home-entry{
    opacity: 1;
}

body.show-entry .menu{
    transition: opacity 1s;
    opacity: 0;
}

body .menu{
    transition: opacity 0.5s;
}


.home-content .swiper-arrow{
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    opacity: 0.25;
    z-index:100000;
    opacity: 0;
    width: 30%;
    height: calc(100% - 150px);
    transition: opacity 1s;
}
.home-content .swiper-arrow:hover{
    opacity: 1 !important;
}
.home-content .swiper-arrow svg{
    width: 24px;
    height: 24px;
}
.home-content .swiper-arrow.next{
    cursor: url(http://huangrui.oss-cn-beijing.aliyuncs.com/RightArrow.png) 1 25, auto;
    right: 10px;
}
.home-content .swiper-arrow.prev{
    cursor: url(http://huangrui.oss-cn-beijing.aliyuncs.com/LeftArrow.png) 1 25, auto;
    left: 10px;
}

.home-content.mouse-moving .swiper-arrow{
    opacity: 0.25;
}

@media only screen and (max-width: 768px) {

    body.home .app > div {
        height: 100%;
    }
    .home-content .swiper-container .swiper-slide > .slide-img {
        width: calc(100% - 84px);
        height: calc(100% - 84px);
    }
    .home-content .swiper-container {
        box-sizing: border-box;
        z-index: 10;
        width: calc(100% + 50px);
        height: 100%;
        position: absolute;
        left: -25px;
    }
    .home-content .slide-title {
        width: 100%;
        text-align: center;
        -webkit-transform: unset;
                transform: unset;
        left: 0;
        display: block;
        padding: 0 24px;
        box-sizing: border-box;
        text-align: center;
    }
    .home-content .swiper-arrow{
        display: none;
    }
}

body.information .menu-information {
    opacity: 0;
}
body.information .menu-information:hover{
    opacity: 1 !important;
}

.information-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.information-content {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.information-content p {
    margin: 0;
}
/*.information-content > div{*/
    /*padding:50px 0;*/
    /*max-width: 768px;*/
    /*z-index: 10;*/
    /*margin: 0 auto*/
/*}*/
.information-navs >div {
    display: inline-block;
    text-transform: capitalize;
    padding:0 3px 0 0;
}
.information-navs >div > a:hover {
    opacity: 0.25;
}

.information-navs >div > a.active {
    color: grey;
}

.information-container .cover-container{
    text-align: center;
    padding-top: 80px;
    padding-bottom: 24px;
    min-height: 347px;
    box-sizing: border-box;
}
.information-container .cover-container img{
    max-width: 360px;
    max-height: 240px;
    width: auto;
    height: auto;
}
.information-container .content-container{
   line-height: 21px;
    width: calc(100% - 280px);
    /*max-width: 768px;*/
    margin: 0 auto;
    padding-bottom: 42px;
}
.information-container .content-container>div{
    min-height: 21px;
}

.information-navs {
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    padding: 24px 20px 5px;
    box-sizing: border-box;
    line-height: 21px;
    background: white;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    width: 100%;
    margin: 0 auto;
    z-index: 100;
}
.content-solo-exhibitions {
    padding-right: 12px;
}

.content-group-exhibitions {
    padding-left: 12px;
}

.content-solo-exhibitions, .content-group-exhibitions {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    vertical-align: top;
}
.exhibition-item {
    position: relative;
    padding-bottom: 21px;
}
.exhibition-item > div {
    font-size: 16px;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
}
.exhibition-title {
    text-align: center;
    line-height:21px;
    padding-bottom: 21px;
}

.exhibition-item > div:first-child {
    width: 64px;
}
.exhibition-item > div:last-child {
    width: calc(100% - 64px);
}
.information-content > div.information-snap-box {
    box-sizing: border-box;
    padding-top: 113px;
}
.information-content > div.information-snap-box#biography {
    padding-top: 24px
}

.information-snap-box{
    border-bottom: black 3px solid;
}

.publication-item > a {
    opacity: 0.25;
}
.writing-item > a {
    opacity: 0.25;
}
#credits, #contact{
    display: flex;
    align-items: center;
}
.information-mobile-navs {
    display: none;
}


@media only screen and (max-width: 768px) {
    .information-navs {
        display: none;
    }
    .information-mobile-navs a.active{
        opacity: 0.25;
    }
    .information-mobile-navs {
        display: block;
        padding: 10px 24px 10px 24px;
        line-height: 32px;
        text-transform: capitalize;
    }
    .information-content {
        font-size: 16px;
        line-height: 20px;
        /*height: calc(100% - 200px);*/
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding: 0 24px;
        box-sizing: border-box;
        position: relative;
    }
    .information-container .content-container {
        width: 100%;
    }
    .information-container {
        /*height: calc(100% - 180px);*/
        /*height: auto;*/
        /*overflow: auto;*/
    }
    body.information .content {
        /*overflow: auto;*/
        height: calc(100% - 104px);
    }
    .content-solo-exhibitions, .content-group-exhibitions {
        width: 100%;
    }
    .information-container .cover-container{
        padding-top: 0px;
        min-height: unset;
    }
    .information-content > div.information-snap-box {
        padding-top: 24px;
    }
    .information-container .content-container {
        line-height: 20px;
        font-size: 16px;
    }
    .exhibition-item > div {
        font-size: 16px;
        line-height: 20px;
    }
    .exhibition-title {
        line-height: 26px;
    }
    .information-content > div.information-snap-box:last-child {
        border: none;
    }
    .information-container .cover-container img {
        width: 100%;
    }
}



body.works .menu-works {
    opacity: 0;
}
body.works .menu-works:hover {
    opacity: 1;
}

.works-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    position: relative;
}

.works-module-nav {
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    padding: 24px 20px 5px;
    box-sizing: border-box;
    line-height: 21px;
    background: white;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    width: calc(100% - 300px);
    margin: 0 auto;
    z-index: 100;
}

.active-work-tile {
    min-height: 69px;
    width: calc(100% - 300px);
    z-index: 100;
    margin: 0 auto;
    background: white;
    text-align: center;
    position: absolute;
    padding: 24px 20px 24px;
    bottom: 0;
    left: 50%;
    line-height: 21px;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    box-sizing: border-box;
}

.works-module-nav .active {
    opacity: 0.25;
}
.works-module-nav a {
    padding: 0 0px;
}

.works-grids {
    padding: 90px 150px 60px;
    z-index: 10;
    width: 100%;
    box-sizing: border-box;;
    height: 100%;
    overflow: auto;
}

.works-grid {
    cursor: pointer;
    /*padding:12px 12px 12px 12px;*/
    padding: 20px 60px;
    width: 33.33%;
    display: inline-block;
    box-sizing: border-box;
}

.works-grid .cover {
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    transition: opacity 500ms;
}

.works-grid:hover .cover {
    opacity: 0;
    transition: opacity 500ms;
}

.works-gallery-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: white;
    z-index: 10000;
    overflow: hidden;
}

.works-gallery-content {
    zIndex: 10000;
    background: white;
}

.works-modal-wrapper .swiper-container {
    width: 100%;
    height: 100%;
}

.works-modal-wrapper .swiper-container .swiper-slide > .image {
    width: calc(100% - 400px);
    height: calc(100% - 300px);
    max-width: 1140px;
    max-height: 760px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    margin-top: -30px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.works-modal-wrapper .swiper-container .swiper-slide > .title {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    display: block;
    bottom: 24px;
}

.works-modal-wrapper{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.works-modal-wrapper .swiper-arrow{
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 20%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    opacity: 0.25;
    z-index:100000;
    opacity: 0;
    height: calc(100% - 150px);
    transition: opacity 1s;
}
.works-modal-wrapper .swiper-arrow:hover{
    opacity: 1 !important;
}
.works-modal-wrapper .swiper-arrow svg{
    width: 24px;
    height: 24px;
}
.works-modal-wrapper .swiper-arrow.next{
    cursor: url(http://huangrui.oss-cn-beijing.aliyuncs.com/RightArrow.png) 1 25, auto;
    right: 10px;
}
.works-modal-wrapper .swiper-arrow.prev{
    left: 10px;
    cursor: url(http://huangrui.oss-cn-beijing.aliyuncs.com/LeftArrow.png) 1 25, auto;
}

.works-modal-wrapper.mouse-moving .swiper-arrow{
    opacity: 0.25;
}
.works-modal-wrapper.mouse-moving .works-modal-close{
    opacity: 1;
}

.works-modal-close {
    transition: opacity 1s;
    opacity: 0;
    position: absolute;
    top: 24px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    cursor: pointer;
    z-index: 10000;
    display: inline-block;
}

.works-modal-close svg {
    width: 20px;
    height: 20px;
}

.works-modal-close:hover {
    opacity: 0 !important;
}


.works-modal-wrapper-open {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 40px;
    bottom: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: white;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10000;
    width: 100%;
    height: 100%;
}


.works-module-mobile-nav {
    display: none;
}



@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .works-grid {
        width: 33.33%;
    }
}

@media only screen and (max-width: 768px) {
    .active-work-tile {
        display: none;
    }
    .works-modal-wrapper .swiper-arrow {
        display: none !important;
    }
    .works-grid {
        width: 50%;
        padding: 48px 12px 12px 12px;
    }
    .works-grids {
        padding: 0;
        height: unset;
    }
    .works-module-nav {
        display: none;
    }
    .works-module-mobile-nav {
        display: block;
        box-sizing: border-box;
        padding: 24px 24px 0 24px;
    }
    .works-module-mobile-nav>a {
        display: block;
    }
    .works-module-mobile-nav>a.active {
        opacity: 0.25;
    }
    .works-modal-wrapper .swiper-container .swiper-slide > .image {
        width: calc(100% - 48px);
        height: calc(100% - 120px);
        top: 80px;
        left: 24px;
        -webkit-transform: unset;
                transform: unset;
    }
    .works-modal-wrapper .swiper-container .swiper-slide > .title {
        width: 100%;
        left: 0;
        font-size: 16px;
        padding: 0 24px;
        line-height: 20px;
        text-align: center;
        -webkit-transform: unset;
                transform: unset;
        box-sizing: border-box;
        top: calc(100% - 50px);
        max-height: 40px;
        overflow: hidden;
        bottom: unset;
    }
    .works-modal-close {
        opacity: 1;
        top: 5px;
    }
    body.works .app-menu-closed  .content {
        height: calc(100% - 104px);
    }
}

body.journals .menu-journal {
    opacity: 0;
}
body.journals .menu-journal:hover{
    opacity: 1 !important;
}

.journals-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.journals-banner {
    height: 50px;
    /*max-width: 768px;*/
    width: calc(100% - 280px);
    margin: 0 auto;
    background:white;
    position:absolute;
    top:0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    z-index: 100;
}

.journals-grids {
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.journals-grids > div{
    /*padding:50px 0;*/
    /*max-width: 768px;*/
    padding: 90px 150px 60px;
    z-index: 10;
    margin: 0 auto;
}

.journals-grids > div > div{
    width: calc(100% + 24px);
    margin:0 -12px;
    box-sizing: border-box;
}

.journal-grid {
    width: 50%;
    padding: 20px 12px;
    box-sizing: border-box;
    display: inline-block;
}

.journal-grid .journal-cover {
    cursor: pointer;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: aliceblue;
    width: 100%;
    height:0;
    padding-bottom: 66.67%;
}
.journal-grid .journal-cover:hover {
    opacity: 0.25;
}

.journal-grid .journal-date {
    text-align: center;
    padding-top: 10px;
    font-size: 11px;
    line-height: 15px;
}

.journal-grid .journal-title {
    width: 100%;
    height: 21px;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    font-size: 16px;
    line-height: 21px;
    padding-top: 0px;
}
.journal-grid .journal-summary {
    padding-top: 15px;
    font-size: 16px;
    line-height: 21px;
    height:63px;
    overflow: hidden;
}

.journal-grid .read-more {
    opacity: 0.25;
    cursor: pointer;
    padding: 0 5px;
}
.journal-grid .read-more:hover {
    opacity: 1;
}
.journal-grid .journal-title {
    position: relative;
    -webkit-transform: unset;
            transform: unset;
    top: unset;
    left: unset;
}

@media only screen and (max-width: 768px) {
    .journals-grids > div > div {
        width: 100%;
        margin: 0;
    }
    .journals-grids > div {
        padding: 0;
    }
    body.journals .app-menu-closed  .content {
        height: calc(100% - 104px);
    }
    .journals-grids {
        padding: 0 24px;
        box-sizing: border-box;
    }
    .journals-grids > div {
        padding-top: 0px;
    }
    .journals-banner {
        display: none;
    }
    .journal-grid {
        width: 100%;
        padding-left:0;
        padding-right: 0;
        padding-bottom: 36px;
    }
    .journal-grid .journal-cover {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 60%;
    }
    .journal-grid .journal-date {
        padding-top: 12px;
        font-size: 16px;
        line-height: 20px;
    }
    .journal-grid .journal-title {
        padding-top: 0px;
        line-height: 32px;
        font-size: 22px;
        height: unset;
        padding-bottom: 10px;
        white-space: normal;
    }
    .journal-grid .journal-summary {
        padding-top: 0;
    }
    .journal-grid .journal-summary {
        font-size: 16px;
        line-height: 20px;
        height: 60px;
    }
    .journal-title {
        position: relative !important;
        height: unset !important;
    }
}


body.journal .menu-journal {
    opacity: 0;
}
body.journal .menu-journal:hover{
    opacity: 1 !important;
}

.journal-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}


.journal-title {
    height: 50px;
    max-width: 768px;
    width: calc(100% - 280px);
    /*width: 100%;*/
    margin: 0 auto;
    background:white;
    position:absolute;
    top:0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    z-index: 100;
    text-align: center;
    padding-top: 24px;
}

.journal-content {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.journal-content > div{
    padding:50px 0;
    max-width: 768px;
    z-index: 10;
    margin: 0 auto;
}


.journal-content  .journal-swiper {
    width: 100%;
    height: 0;
    padding-bottom:68%;
    position: relative;
}
.journal-content  .journal-swiper .swiper-container{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
}
.journal-content  .journal-swiper .swiper-container .swiper-slide{
    background-color: aliceblue;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}


.journal-swiper .swiper-arrow{
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 20%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    opacity: 0.25;
    z-index:100000;
    opacity: 0;
    height: calc(100% - 150px);
    transition: opacity 1s;
}
.journal-swiper .swiper-arrow:hover{
    opacity: 1 !important;
}
.journal-swiper .swiper-arrow svg{
    width: 24px;
    height: 24px;
}
.journal-swiper .swiper-arrow.next{
    cursor: url(http://huangrui.oss-cn-beijing.aliyuncs.com/RightArrow.png) 1 25, auto;
    right: 10px;
}
.journal-swiper .swiper-arrow.prev{
    left: 10px;
    cursor: url(http://huangrui.oss-cn-beijing.aliyuncs.com/LeftArrow.png) 1 25, auto;
}

.journal-swiper.mouse-moving .swiper-arrow{
    opacity: 0.25;
}



.journal-content .journal-md {
    padding-top: 24px;
}
.journal-content .journal-md p{
    -webkit-user-select: text;  /* Chrome 49+ */
    -moz-user-select: text;     /* Firefox 43+ */
    -ms-user-select: text;      /* No support yet */
    user-select: text;          /* Likely future */
}
.journal-content .journal-md p::-moz-selection{
    /*color: rgba(0,0,0,0);*/
    color: rgba(255,255,255,1);
    background: rgba(0,0,0,1);
    /*background: rgba(255,255,255,1);*/
}
.journal-content .journal-md p::selection {
    color: rgba(255,255,255,1);
    /*color: rgba(0,0,0,0);*/
    background: rgba(0,0,0,1);
    /*background: rgba(255,255,255,1);*/
}

.journal-content .journal-md p > img{
    max-width: 60% !important;
    height: auto !important;
    margin: 0 auto;
    display: none !important;
}

.journal-content > div.journal-footer {
    padding: 10px 0 50px 0;
    width: 100%;
    max-width: unset;
    border-top: solid black 3px;
}

.journal-footer .journal-footer-swiper {
    max-width: 768px;
    z-index: 10;
    position: relative;
    margin: 0 auto;
}
.journal-footer .journal-footer-swiper > div {
    padding-bottom:20%;
    height: 0;
    width: calc(100% + 24px);
    margin: 0 -12px;
    position: relative;
}
.journal-footer .journal-footer-swiper .swiper-container {
    position: absolute;
    width:100%;
    top:0;
    left:0;
    height: 100%;
}
.journal-footer .journal-footer-swiper .swiper-slide{
    padding:0 12px;
    box-sizing: border-box;
}
.journal-footer .journal-footer-swiper .swiper-slide>div{
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
.journal-footer .journal-footer-swiper .swiper-slide:hover>div{
    background-image: unset !important;
    background-color: white;
}

.journal-footer .journal-footer-swiper .swiper-slide>div>div{
    width: 100%;
    height: 100%;
    position: relative;
}
.journal-footer .journal-footer-swiper .swiper-slide>div>div>a{
    display: none;
    text-align: center;
    width: 80%;
    position: absolute;;
    top: 50%;
    left: 50%;
    overflow: hidden;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.journal-footer .journal-footer-date {
    font-size: 11px;
    line-height: 16px;
}
.journal-footer .journal-footer-title {
    font-size: 16px;
    line-height: 21px;
}

.journal-footer .journal-footer-swiper .swiper-slide:hover>div>div>a{
    display:block;
}
body.journal .journal-date {
    display: none;
}

@media only screen and (max-width: 768px) {
    body.journal .app-menu-closed  .content {
        height: calc(100% - 104px);
    }
    body.journal .app > div {
        padding: 0;
    }
    body.journal .content {
        width: 100%;
        margin: 0;
        box-sizing: border-box;
    }
    body.journal .journal-container {
        box-sizing: border-box;
        padding: 0 24px;
        -webkit-overflow-scrolling: touch;
        overflow: auto;
        font-size: 16px;
        line-height: 20px;
    }
    body.journal .journal-content {
        height: unset;
    }
    body.journal .mobile-menu{
        padding: 0 24px;
        box-sizing: border-box;
    }
    body.journal .journal-title {
        font-size: 20px;
        line-height: 32px;
        width: 100%;
        padding: 0;
    }
    body.journal .journal-date {
        display: block;
        padding-top: 24px;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
    }
    body.journal .journal-footer {
        display: none;
    }
    .journal-content > div {
        padding-top: 30px;
    }
}

