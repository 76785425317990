@font-face {
    font-family: 'Graphik Web';
    src: url('http://huangrui-media.jilinchen.com/Graphik-MediumItalic-Web.woff2') format('woff2'),
    url('http://huangrui-media.jilinchen.com/Graphik-MediumItalic-Web.woff') format('woff');
    font-weight:  500;
    font-style:   italic;
    font-stretch: normal;
}

/*.Graphik-MediumItalic-Web {*/
    /*font-family: 'Graphik Web';*/
    /*font-weight:  500;*/
    /*font-style:   italic;*/
    /*font-stretch: normal;*/
/*}*/

@font-face {
    font-family: 'Graphik Web';
    src: url('http://huangrui-media.jilinchen.com/Graphik-Medium-Web.woff2') format('woff2'),
    url('http://huangrui-media.jilinchen.com/Graphik-Medium-Web.woff') format('woff');
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
}



html, body, #root {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}

body {
    /*min-height: 560px;*/
    font-size: 16px;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-family: 'Graphik Web';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}
a:visited, a {
    color: black;
}


* {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}


