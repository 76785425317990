body.information .menu-information {
    opacity: 0;
}
body.information .menu-information:hover{
    opacity: 1 !important;
}

.information-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.information-content {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.information-content p {
    margin: 0;
}
/*.information-content > div{*/
    /*padding:50px 0;*/
    /*max-width: 768px;*/
    /*z-index: 10;*/
    /*margin: 0 auto*/
/*}*/
.information-navs >div {
    display: inline-block;
    text-transform: capitalize;
    padding:0 3px 0 0;
}
.information-navs >div > a:hover {
    opacity: 0.25;
}

.information-navs >div > a.active {
    color: grey;
}

.information-container .cover-container{
    text-align: center;
    padding-top: 80px;
    padding-bottom: 24px;
    min-height: 347px;
    box-sizing: border-box;
}
.information-container .cover-container img{
    max-width: 360px;
    max-height: 240px;
    width: auto;
    height: auto;
}
.information-container .content-container{
   line-height: 21px;
    width: calc(100% - 280px);
    /*max-width: 768px;*/
    margin: 0 auto;
    padding-bottom: 42px;
}
.information-container .content-container>div{
    min-height: 21px;
}

.information-navs {
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    padding: 24px 20px 5px;
    box-sizing: border-box;
    line-height: 21px;
    background: white;
    transform: translate(-50%, 0);
    width: 100%;
    margin: 0 auto;
    z-index: 100;
}
.content-solo-exhibitions {
    padding-right: 12px;
}

.content-group-exhibitions {
    padding-left: 12px;
}

.content-solo-exhibitions, .content-group-exhibitions {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    vertical-align: top;
}
.exhibition-item {
    position: relative;
    padding-bottom: 21px;
}
.exhibition-item > div {
    font-size: 16px;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
}
.exhibition-title {
    text-align: center;
    line-height:21px;
    padding-bottom: 21px;
}

.exhibition-item > div:first-child {
    width: 64px;
}
.exhibition-item > div:last-child {
    width: calc(100% - 64px);
}
.information-content > div.information-snap-box {
    box-sizing: border-box;
    padding-top: 113px;
}
.information-content > div.information-snap-box#biography {
    padding-top: 24px
}

.information-snap-box{
    border-bottom: black 3px solid;
}

.publication-item > a {
    opacity: 0.25;
}
.writing-item > a {
    opacity: 0.25;
}
#credits, #contact{
    display: flex;
    align-items: center;
}
.information-mobile-navs {
    display: none;
}


@media only screen and (max-width: 768px) {
    .information-navs {
        display: none;
    }
    .information-mobile-navs a.active{
        opacity: 0.25;
    }
    .information-mobile-navs {
        display: block;
        padding: 10px 24px 10px 24px;
        line-height: 32px;
        text-transform: capitalize;
    }
    .information-content {
        font-size: 16px;
        line-height: 20px;
        /*height: calc(100% - 200px);*/
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding: 0 24px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
    }
    .information-container .content-container {
        width: 100%;
    }
    .information-container {
        /*height: calc(100% - 180px);*/
        /*height: auto;*/
        /*overflow: auto;*/
    }
    body.information .content {
        /*overflow: auto;*/
        height: calc(100% - 104px);
    }
    .content-solo-exhibitions, .content-group-exhibitions {
        width: 100%;
    }
    .information-container .cover-container{
        padding-top: 0px;
        min-height: unset;
    }
    .information-content > div.information-snap-box {
        padding-top: 24px;
    }
    .information-container .content-container {
        line-height: 20px;
        font-size: 16px;
    }
    .exhibition-item > div {
        font-size: 16px;
        line-height: 20px;
    }
    .exhibition-title {
        line-height: 26px;
    }
    .information-content > div.information-snap-box:last-child {
        border: none;
    }
    .information-container .cover-container img {
        width: 100%;
    }
}

