@import '~swiper/dist/css/swiper.css';

.home-entry, .home-loading, .home-error {
    display:flex;
    flex: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 1s;
}

.home-content {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

.home-content .swiper-container {
    z-index: 10;
    width: calc(100% + 50px);
    height: 100%;
    position: absolute;
    left: -29px;
}

.home-content .swiper-container .swiper-slide{
    border-right: solid black 1.5px;
    border-left: solid black 1.5px;
    box-sizing: border-box;
}

.home-entry, .home-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    text-align: center;
}

.home-content .swiper-container .swiper-slide > .slide-img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 60%;
    height: 60%;
    max-width: 1140px;
    max-height: 760px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
.home-content .swiper-container .swiper-slide > a > img{
    width: auto;
    height: auto;
    max-width: 75%;
    max-height: 75%;
}

.home-content .slide-title {
    z-index: 11;
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%, 0);
    display: inline-block;
}

body.show-entry .home-entry{
    opacity: 1;
}

body.show-entry .menu{
    transition: opacity 1s;
    opacity: 0;
}

body .menu{
    transition: opacity 0.5s;
}


.home-content .swiper-arrow{
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0.25;
    z-index:100000;
    opacity: 0;
    width: 30%;
    height: calc(100% - 150px);
    transition: opacity 1s;
}
.home-content .swiper-arrow:hover{
    opacity: 1 !important;
}
.home-content .swiper-arrow svg{
    width: 24px;
    height: 24px;
}
.home-content .swiper-arrow.next{
    cursor: url(http://huangrui.oss-cn-beijing.aliyuncs.com/RightArrow.png) 1 25, auto;
    right: 10px;
}
.home-content .swiper-arrow.prev{
    cursor: url(http://huangrui.oss-cn-beijing.aliyuncs.com/LeftArrow.png) 1 25, auto;
    left: 10px;
}

.home-content.mouse-moving .swiper-arrow{
    opacity: 0.25;
}

@media only screen and (max-width: 768px) {

    body.home .app > div {
        height: 100%;
    }
    .home-content .swiper-container .swiper-slide > .slide-img {
        width: calc(100% - 84px);
        height: calc(100% - 84px);
    }
    .home-content .swiper-container {
        box-sizing: border-box;
        z-index: 10;
        width: calc(100% + 50px);
        height: 100%;
        position: absolute;
        left: -25px;
    }
    .home-content .slide-title {
        width: 100%;
        text-align: center;
        transform: unset;
        left: 0;
        display: block;
        padding: 0 24px;
        box-sizing: border-box;
        text-align: center;
    }
    .home-content .swiper-arrow{
        display: none;
    }
}
