body.journals .menu-journal {
    opacity: 0;
}
body.journals .menu-journal:hover{
    opacity: 1 !important;
}

.journals-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.journals-banner {
    height: 50px;
    /*max-width: 768px;*/
    width: calc(100% - 280px);
    margin: 0 auto;
    background:white;
    position:absolute;
    top:0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 100;
}

.journals-grids {
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.journals-grids > div{
    /*padding:50px 0;*/
    /*max-width: 768px;*/
    padding: 90px 150px 60px;
    z-index: 10;
    margin: 0 auto;
}

.journals-grids > div > div{
    width: calc(100% + 24px);
    margin:0 -12px;
    box-sizing: border-box;
}

.journal-grid {
    width: 50%;
    padding: 20px 12px;
    box-sizing: border-box;
    display: inline-block;
}

.journal-grid .journal-cover {
    cursor: pointer;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: aliceblue;
    width: 100%;
    height:0;
    padding-bottom: 66.67%;
}
.journal-grid .journal-cover:hover {
    opacity: 0.25;
}

.journal-grid .journal-date {
    text-align: center;
    padding-top: 10px;
    font-size: 11px;
    line-height: 15px;
}

.journal-grid .journal-title {
    width: 100%;
    height: 21px;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    font-size: 16px;
    line-height: 21px;
    padding-top: 0px;
}
.journal-grid .journal-summary {
    padding-top: 15px;
    font-size: 16px;
    line-height: 21px;
    height:63px;
    overflow: hidden;
}

.journal-grid .read-more {
    opacity: 0.25;
    cursor: pointer;
    padding: 0 5px;
}
.journal-grid .read-more:hover {
    opacity: 1;
}
.journal-grid .journal-title {
    position: relative;
    transform: unset;
    top: unset;
    left: unset;
}

@media only screen and (max-width: 768px) {
    .journals-grids > div > div {
        width: 100%;
        margin: 0;
    }
    .journals-grids > div {
        padding: 0;
    }
    body.journals .app-menu-closed  .content {
        height: calc(100% - 104px);
    }
    .journals-grids {
        padding: 0 24px;
        box-sizing: border-box;
    }
    .journals-grids > div {
        padding-top: 0px;
    }
    .journals-banner {
        display: none;
    }
    .journal-grid {
        width: 100%;
        padding-left:0;
        padding-right: 0;
        padding-bottom: 36px;
    }
    .journal-grid .journal-cover {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 60%;
    }
    .journal-grid .journal-date {
        padding-top: 12px;
        font-size: 16px;
        line-height: 20px;
    }
    .journal-grid .journal-title {
        padding-top: 0px;
        line-height: 32px;
        font-size: 22px;
        height: unset;
        padding-bottom: 10px;
        white-space: normal;
    }
    .journal-grid .journal-summary {
        padding-top: 0;
    }
    .journal-grid .journal-summary {
        font-size: 16px;
        line-height: 20px;
        height: 60px;
    }
    .journal-title {
        position: relative !important;
        height: unset !important;
    }
}

